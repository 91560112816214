"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = [{
  src: require("../assets/redesign2020/logo-garden/pwc.png"),
  alt: "PWC"
}, {
  src: require("../assets/redesign2020/logo-garden/carvana.png"),
  alt: "Carvana"
}, {
  src: require("../assets/redesign2020/logo-garden/peloton.png"),
  alt: "Peloton"
}, {
  src: require("../assets/redesign2020/logo-garden/mattel.png"),
  alt: "Mattel"
}, {
  src: require("../assets/redesign2020/logo-garden/23andme.png"),
  alt: "23 and Me"
}, {
  src: require("../assets/redesign2020/logo-garden/dcu.png"),
  alt: "DCU"
}, {
  src: require("../assets/redesign2020/logo-garden/fareway.png"),
  alt: "Fareway"
}, {
  src: require("../assets/redesign2020/logo-garden/yelp.png"),
  alt: "Yelp"
}, {
  src: require("../assets/redesign2020/logo-garden/prh.png"),
  alt: "Penguin Random House"
}];
exports.default = _default;